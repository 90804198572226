import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import './App.css'
import { renderToStaticMarkup } from 'react-dom/server'
import RiskAssessmentTemplate from './RiskAssessmentTemplate'
import { displayErrorNotification, successNotification, displayKeyNotFoundNotification, loadNotification } from './Notifications'
import APIConnection from './APIService'
import BackDropdown from './backDropdown'

function initLocalStorageItems (editorRef: any): void {
  const userKeyFromQueryParam: string | null = new URLSearchParams(window.location.search).get('user_key')
  if (userKeyFromQueryParam !== null && userKeyFromQueryParam !== '') {
    localStorage.setItem('user_key', userKeyFromQueryParam)
  } else if (localStorage.getItem('user_key') === null || localStorage.getItem('user_key') === '') {
    setTimeout(() => {
      editorRef.current.mode.set('readonly')
      displayKeyNotFoundNotification(editorRef.current)
    }, 1500)
  }

  const userFileIdFromQueryParam: string | null = new URLSearchParams(window.location.search).get('user_file_id')
  if (userFileIdFromQueryParam !== null && userFileIdFromQueryParam !== '') {
    localStorage.removeItem('template_file_id')
    localStorage.removeItem('risk_file_id')
    localStorage.removeItem('isRiskAssessment')
    localStorage.setItem('user_file_id', userFileIdFromQueryParam)
  }

  const templateFileIdFromQueryParam: string | null = new URLSearchParams(window.location.search).get('template_file_id')
  if (templateFileIdFromQueryParam !== null && templateFileIdFromQueryParam !== '') {
    localStorage.removeItem('user_file_id')
    localStorage.removeItem('risk_file_id')
    localStorage.removeItem('isRiskAssessment')
    localStorage.setItem('template_file_id', templateFileIdFromQueryParam)
  }

  const workspaceFromQueryParam: string | null = new URLSearchParams(window.location.search).get('workspace')
  if (workspaceFromQueryParam !== null && workspaceFromQueryParam !== '') {
    localStorage.setItem('workspace', workspaceFromQueryParam)
  }

  const appVersionFromQueryParam: string | null = new URLSearchParams(window.location.search).get('version')
  if (appVersionFromQueryParam !== null && appVersionFromQueryParam !== '') {
    localStorage.setItem('version', appVersionFromQueryParam)
  }

  const isTemplateFromQueryParam: string | null = new URLSearchParams(window.location.search).get('isTemplate')
  if (isTemplateFromQueryParam !== null && isTemplateFromQueryParam !== '') {
    localStorage.setItem('is_template', isTemplateFromQueryParam)
  }

  const isRiskAssessmentFromQueryParam: string | null = new URLSearchParams(window.location.search).get('isRiskAssessment')
  if (isRiskAssessmentFromQueryParam !== null && isRiskAssessmentFromQueryParam !== '') {
    localStorage.setItem('isRiskAssessment', isRiskAssessmentFromQueryParam)
  }

  const riskFileIdFromQueryParam: string | null = new URLSearchParams(window.location.search).get('risk_file_id')
  if (riskFileIdFromQueryParam !== null && riskFileIdFromQueryParam !== '') {
    localStorage.removeItem('user_file_id')
    localStorage.removeItem('template_file_id')
    localStorage.setItem('risk_file_id', riskFileIdFromQueryParam)
  }

  const workspaceIDFromQueryParam: string | null = new URLSearchParams(window.location.search).get('current_workspace')
  if (workspaceIDFromQueryParam !== null && workspaceIDFromQueryParam !== '') {
    localStorage.setItem('current_workspace', workspaceIDFromQueryParam)
  }
}

export default function App (): any {
  const editorRef = useRef<any>(null)
  const APIURL = useRef('')
  const DocumentName = useRef('')
  const documentLogo = useRef('')
  const paramShowLogo = useRef(true)
  const generateCount = useRef(0)
  const generateLimit = useRef(0)
  const isRiskAssessment = useRef(false)
  const isTemplate = useRef(false)

  const [documentName, setDocumentName] = React.useState<string>('')
  const [initialValue, setInitialValue] = React.useState<string>('')

  let loadingNotification: any

  const setupEditor = (): void => {
    initLocalStorageItems(editorRef)

    if (window.location.search.includes('user_key')) {
      if (window.location.host.startsWith('localhost')) {
        window.location.href = 'http://' + window.location.host
      } else {
        window.history.replaceState(null, '', window.location.pathname)
      }
    }
    APIURL.current = (localStorage.getItem('version') === 'test') ? 'https://mybaseonline.com/version-test/api/1.1/wf' : 'https://mybaseonline.com/api/1.1/wf'
    let requestURL = `${APIURL.current}/get_json_value_from_user_file/`
    let requestData: { [key: string]: string | null } = {
      user_file: localStorage.getItem('user_file_id')
    }

    const apiService = new APIConnection(localStorage.getItem('user_key') as string)

    if (localStorage.getItem('template_file_id') !== null && localStorage.getItem('template_file_id') !== '') {
      isTemplate.current = true
      requestURL = `${APIURL.current}/get_json_value_from_template_file/`
      requestData = {
        template_file: (localStorage.getItem('template_file_id') as string)
      }
    }

    if (localStorage.getItem('risk_file_id') !== null && localStorage.getItem('risk_file_id') !== '') {
      isRiskAssessment.current = true
      requestURL = `${APIURL.current}/get_risk_assessment`
      requestData = {
        risk_file_id: localStorage.getItem('risk_file_id'),
        current_workspace: localStorage.getItem('current_workspace')
      }

      apiService.post(requestURL, requestData).then((response) => {
        const userFileName = response.data.response.project_name !== undefined ? response.data.response.project_name as string : ''
        setDocumentName(userFileName)
        DocumentName.current = userFileName
        editorRef.current.setContent(renderToStaticMarkup(<RiskAssessmentTemplate data={response.data.response}/>))

        generateCount.current = response.data.response.workspace_count !== undefined ? response.data.response.workspace_count as number : 0
        generateLimit.current = response.data.response.workspace_limit !== undefined ? response.data.response.workspace_limit as number : 0
        updateGenerateCounter()
        editorRef.current.mode.set('readonly')
        const exportButton: any = document.querySelector('button[title="Save & Export PDF"]')
        const exportText: any = exportButton.querySelector('.tox-tbtn__select-label')
        exportText.style.cursor = 'pointer'
        exportButton.setAttribute('aria-disabled', 'false')
        exportButton.classList.remove('tox-tbtn--disabled')
        exportButton.style.cursor = 'pointer'
        const logoButton: any = document.querySelector('button[title="If enabled will display the logo on the export"]')
        logoButton.setAttribute('aria-disabled', 'false')
        logoButton.classList.remove('tox-tbtn--disabled')
        logoButton.style.cursor = 'pointer'
        const logoText: any = logoButton.querySelector('.tox-tbtn__select-label')
        logoText.style.cursor = 'pointer'

        editorRef.current.dom.addStyle('body { max-width: 1200px !important }')
        loadingNotification.close()
      })
        .catch((err) => {
          console.log(err)
        })
    } else {
      apiService.post(requestURL, requestData).then((response) => {
        const documentJsonValue: string = response.data.response.document_json_value !== undefined ? response.data.response.document_json_value as string : ''
        const userFileName = response.data.response.user_file_name !== undefined ? response.data.response.user_file_name as string : ''

        setTimeout(() => {
          successNotification(editorRef.current, 'Document editing is not synchronous, don\'t forget to save.')
          setInitialValue(documentJsonValue)
          setDocumentName(userFileName)
          generateCount.current = response.data.response.workspace_count !== undefined ? response.data.response.workspace_count as number : 0
          generateLimit.current = response.data.response.workspace_limit !== undefined ? response.data.response.workspace_limit as number : 0
          DocumentName.current = userFileName
          documentLogo.current = response.data.response.user_profile_picture
          updateGenerateCounter()
          loadingNotification.close()
        }, 1000)
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const updateGenerateCounter = (): void => {
    const spanList = document.querySelectorAll('span')
    spanList.forEach(element => {
      if (element.innerHTML.includes(' left')) {
        if (isTemplate.current) {
          element.innerHTML = ''
        } else {
          element.innerHTML = (generateLimit.current - generateCount.current).toString() + ' left'
        }
      }
    })
  }

  const downloadPDF = (pdfBlob: string): void => {
    const link = document.createElement('a')
    if (link.download !== undefined) { // feature detection
      link.setAttribute('href', pdfBlob)
      link.setAttribute('download', DocumentName.current + '.pdf')
      link.style.visibility = 'hidden'
      link.click()
    }
    generateCount.current = generateCount.current + 1
    updateGenerateCounter()
  }

  const saveDocumentPDF = (): void => {
    const apiService = new APIConnection(localStorage.getItem('user_key') as string)
    const generatingNotification = loadNotification(editorRef.current, 'Generating PDF...')

    let requestURL: string = APIURL.current
    let requestData: any = {}

    if (isTemplate.current) {
      requestURL = requestURL + '/generate_template_pdf'
      requestData = {
        template_file: localStorage.getItem('template_file_id')
      }
    } else if (isRiskAssessment.current) {
      requestURL = requestURL + '/generate_risk_pdf'
      requestData = {
        risk_file: localStorage.getItem('risk_file_id'),
        html_content: `${editorRef.current.getContent() as string}<style> @page landscape { size: A4 landscape; } body { page: landscape }</style>`,
        show_logo: paramShowLogo.current
      }
    } else {
      requestURL = requestURL + '/generate_pdf'
      requestData = {
        user_file: localStorage.getItem('user_file_id'),
        show_logo: paramShowLogo.current
      }
    }

    apiService.post(requestURL, requestData).then((res) => {
      generatingNotification.close()
      if ('error' in res.data.response) {
        displayErrorNotification(editorRef.current, res.data.response.error)
      } else {
        downloadPDF(res.data.response.pdf_blob)
        successNotification(editorRef.current, 'Document saved and PDF generated!')
      }
    }).catch((err) => console.log(err))
  }

  const saveDocument = (savePDF: boolean, documentValue: any): void => {
    const savingNotif = loadNotification(editorRef.current, 'Saving document...')

    const documentJsonValue = documentValue
    let requestURL = `${APIURL.current}/savedocument/`
    const apiService = new APIConnection(localStorage.getItem('user_key') as string)
    const userFileID = localStorage.getItem('user_file_id') as string
    let requestData: any = {
      user_file: userFileID,
      document_json_value: documentJsonValue
    }

    if (localStorage.getItem('template_file_id') !== null && localStorage.getItem('template_file_id') !== '') {
      requestURL = `${APIURL.current}/savetemplate/`
      requestData = { template_file: (localStorage.getItem('template_file_id') as string), document_json_value: documentJsonValue }
    }

    apiService.post(requestURL, requestData).then(() => {
      savingNotif.close()
      if (!savePDF) {
        successNotification(editorRef.current, 'Document saved!')
      } else saveDocumentPDF()
    })
      .catch((err) => {
        console.log(err.response)
        if (!savePDF) {
          savingNotif.close()
        }
        displayErrorNotification(editorRef.current, 'Error saving, check console.')
      })
  }

  return (
     <div style={{ backgroundColor: 'rgb(240, 245, 250)' }}>
       <div style={{ margin: 'auto' }}>
         <BackDropdown documentName={documentName} />
          <div>
         <Editor
           onInit={(evt, editor): any => {
             editorRef.current = editor
             setupEditor()
           }}
           apiKey={'2bjxwlki24uexo3zfczlxe0sdoxytrihnf22hw0mzkp02sdr'}
           initialValue={initialValue}
           init={{
             toolbar_sticky: true,
             toolbar_sticky_offset: 73,
             plugins: 'export save autoresize anchor autolink codesample image link lists media searchreplace pagebreak table wordcount checklist casechange pageembed advtable advcode editimage inlinecss pagebreak emoticons charmap wordcount',
             toolbar: 'save exportButton undo redo displayLogo blocks fontsize bold italic underline strikethrough image table pagebreak align lineheight checklist numlist bullist indent outdent emoticons charmap link casechange removeformat',
             tinycomments_mode: 'embedded',
             toolbar_mode: 'wrap',
             automatic_uploads: true,
             file_picker_types: 'image',
             file_picker_callback: (cb, value, meta) => {
               const input = document.createElement('input')
               input.setAttribute('type', 'file')
               input.setAttribute('accept', 'image/*')

               input.addEventListener('change', (e: any) => {
                 const file = e.target.files[0]
                 const reader = new FileReader()
                 reader.addEventListener('load', () => {
                   const id = `blobid${(new Date()).getTime()}`
                   const blobCache = editorRef.current.editorUpload.blobCache
                   // @ts-expect-error
                   const base64 = reader.result.split(',')[1]
                   const blobInfo = blobCache.create(id, file, base64)
                   blobCache.add(blobInfo)
                   cb(blobInfo.blobUri(), { title: file.name })
                 })
                 reader.readAsDataURL(file)
               })

               input.click()
             },
             autoresize_overflow_padding: 80,
             height: 1000,
             pagebreak_separator: '<hr class="page-break" />',
             content_style: `
               body {
                  background: #fff;
                  min-height: 1000px!important;
                  font-family: 'Arial';
               }
               table, img{max-width:100%!important}
               td { word-wrap: anywhere }
               @media (min-width: 840px) {
                  html {
                    background: #eceef4;
                    padding: 0.5rem;
                  }
                  body {
                    background-color: #fff;
                    box-shadow: 0 0 4px rgba(0, 0, 0, .15);
                    box-sizing: border-box;
                    margin: 1rem auto 0;
                    max-width: 820px;
                    padding-top: 35px;
                    padding-bottom: 45px;
                  }
               }
               `,
             save_onsavecallback: () => {
               saveDocument(false, editorRef.current.getContent())
             },
             setup: function (editor) {
               editor.ui.registry.addButton('exportButton', {
                 icon: 'export',
                 text: 'num left',
                 tooltip: 'Save & Export PDF',
                 onAction: function () {
                   saveDocument(true, editorRef.current.getContent())
                 },
                 // @ts-expect-error
                 onSetup: (api): void => {
                   const apiFunction = (eventAPI: any): void => {
                     api.setEnabled(true)
                   }
                   editor.on('init', apiFunction)
                 }
               })
               editor.ui.registry.addToggleButton('displayLogo', {
                 text: 'Logo',
                 tooltip: 'If enabled will display the logo on the export',
                 onAction: (api) => {
                   api.setActive(!api.isActive())
                   paramShowLogo.current = api.isActive()
                 },
                 // @ts-expect-error
                 onSetup: (api): void => {
                   api.setActive(true)
                 }
               })
               editor.ui.registry.addIcon('save', '<svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.1" viewBox="0 0 17 17" height="17px" width="17px" xmlns="http://www.w3.org/2000/svg"><g></g><path d="M14.164 0h-12.664c-0.827 0-1.5 0.673-1.5 1.5v14c0 0.827 0.673 1.5 1.5 1.5h14c0.827 0 1.5-0.673 1.5-1.5v-12.724l-2.836-2.776zM8 1v4h3v-4h1v5h-8v-5h4zM3 16v-6h11v6h-11zM16 15.5c0 0.275-0.225 0.5-0.5 0.5h-0.5v-7h-13v7h-0.5c-0.276 0-0.5-0.225-0.5-0.5v-14c0-0.275 0.224-0.5 0.5-0.5h1.5v6h10v-6h0.756l2.244 2.196v12.304z"></path></svg>')
               editor.ui.registry.addIcon('export', '<svg width="15" viewBox="22 -941 640 800" xmlns="http://www.w3.org/2000/svg"><path d="M 82 -141 C 66 -141 52 -147 40 -159 C 28 -171 22 -185 22 -201 L 22 -881 C 22 -897 28 -911 40 -923 C 52 -935 66 -941 82 -941 L 443 -941 L 662 -722 L 662 -201 C 662 -185 656 -171 644 -159 C 632 -147 618 -141 602 -141 L 82 -141 Z M 431.028 -698.38 L 432.155 -900.155 L 63.972 -901.282 L 65.099 -182.972 L 616.648 -180.718 L 618.901 -696.127 L 431.028 -698.38 Z"/><text style="fill: rgb(255, 0, 0); font-family: Arial, sans-serif; font-size: 41.4px; font-weight: 700; white-space: pre;" transform="matrix(5.395554, 0, 0, 5.123292, -1250.337177, 2436.932898)" x="254.085" y="-556.338">PDF</text></svg>')
               editor.on('init', function (e) {
                 loadingNotification = loadNotification(editorRef.current, 'Loading...')
               })
             },
             menubar: false,
             branding: false,
             elementpath: false
           }}
       />
            </div>
       </div>
     </div>
  )
}
