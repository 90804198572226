export const successNotification = (editor: { notificationManager: any }, textNotif: string): void => {
  editor.notificationManager.open({
    text: textNotif,
    type: 'success',
    icon: 'checkmark',
    timeout: 3000
  })
}

export const displayErrorNotification = (editor: { notificationManager: any }, textNotif: string): void => {
  editor.notificationManager.open({
    text: textNotif,
    type: 'error',
    icon: 'notice',
    timeout: 3000
  })
}

export const displayKeyNotFoundNotification = (editor: { notificationManager: any }): void => {
  closeLastNotification(editor)
  editor.notificationManager.open({
    text: 'User Key not found. Please log out and then login to enable this feature',
    type: 'error',
    icon: 'notice',
    timeout: 3000
  })
}

export const loadNotification: any = (editor: { notificationManager: any }, textNotif: string, iconNotif: string = 'document-properties'): void => {
  return editor.notificationManager.open({
    text: textNotif,
    type: 'info',
    icon: iconNotif,
    closeButton: false
  })
}

export const closeLastNotification = (editor: { notificationManager: { close: () => void } }): void => {
  editor.notificationManager.close()
}
