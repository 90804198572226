import React from 'react'

const RiskAssessmentTemplate = (props: any): JSX.Element => {
  return (
        <>
            <h1 style={{ lineHeight: 1 }} data-mce-style="line-height: 1;">
              <span style={{ fontSize: '24pt' }} data-mce-style="font-size: 24pt;">
                {props.data.project_name}
              </span>
            </h1>
            <table
                style={{ borderCollapse: 'collapse', width: '100%', borderWidth: '1px', borderSpacing: 0, borderColor: '#95A5A6', borderStyle: 'solid' }}
                border={1} width="601" cellSpacing="0" cellPadding="4">
                <colgroup>
                    <col style={{ width: '179px' }} width="170"/>
                        <col style={{ width: '421px' }} width="413"/>
                </colgroup>
                <tbody>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="185" height="10">
                        <strong>Risk Assessment (Activity):</strong>
                    </td>
                    <td style={{ padding: '4px', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data['Risk Assessment']}
                    </td>
                </tr>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="11">
                        <strong>Assessor:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data.accessor}
                    </td>
                </tr>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="11">
                        <strong>Signature:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">

                    </td>
                </tr>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="11">
                        <strong>Company Name:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data.company_name}
                    </td>
                </tr>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="11">
                        <strong>Work Start Date:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data.start_date}
                    </td>
                </tr>
                <tr>
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="11">
                        <strong>Review Date:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data.end_date}
                    </td>
                </tr>
                <tr >
                    <td style={{ background: 'rgb(236, 240, 241)', borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }}
                        width="170" height="29">
                        <strong>Location:</strong>
                    </td>
                    <td style={{ borderWidth: '1px', borderColor: 'rgb(149, 165, 166)' }} width="413">
                        {props.data.location}
                    </td>
                </tr>
                </tbody>
            </table>

            <p style={{ marginBottom: '0px' }}><br/></p>
            <table
              style={{ borderCollapse: 'collapse', width: '100%', height: '274.594px' }}
              data-mce-style='border-collapse: collapse; width: 100%; height: 274.594px;'
              border={1}
            >
              <colgroup>
                <col style={{ width: '54.0646%' }} data-mce-style='width: 54.0646%;' />
                <col style={{ width: '45.8873%' }} data-mce-style='width: 45.8873%;' />
              </colgroup>
              <tbody>
                <tr style={{ height: '274.594px' }} data-mce-style='height: 274.594px;'>
                  <td
                    style={{ borderStyle: 'hidden', borderWidth: 0, height: '274.594px' }}
                    data-mce-style='border-style: hidden; border-width: 0px; height: 274.594px;'
                  >
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '96.2618%',
                        height: '179.625px',
                        borderWidth: 1,
                        borderStyle: 'hidden'
                      }}
                      data-mce-style='border-collapse: collapse; width: 96.2618%; height: 179.625px; border-width: 1px; border-style: hidden;'
                      border={1}
                    >
                      <colgroup>
                        <col
                          style={{ width: '8.88154%' }}
                          data-mce-style='width: 8.88154%;'
                        />
                        <col
                          style={{ width: '27.4005%' }}
                          data-mce-style='width: 27.4005%;'
                        />
                        <col
                          style={{ width: '12.6609%' }}
                          data-mce-style='width: 12.6609%;'
                        />
                        <col
                          style={{ width: '12.6609%' }}
                          data-mce-style='width: 12.6609%;'
                        />
                        <col
                          style={{ width: '12.6609%' }}
                          data-mce-style='width: 12.6609%;'
                        />
                        <col
                          style={{ width: '12.6609%' }}
                          data-mce-style='width: 12.6609%;'
                        />
                        <col
                          style={{ width: '12.8499%' }}
                          data-mce-style='width: 12.8499%;'
                        />
                      </colgroup>
                      <tbody>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '156.734px',
                              verticalAlign: 'top',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1,
                              transformOrigin: 'top right',
                              transform: 'rotate(-90deg) translate(-60px, - 30px)',
                              wordBreak: 'keep-all',
                              wordWrap: 'normal'
                            }}
                            rowSpan={6}
                            data-mce-style='word-break: keep-all !important; word-wrap: normal !important;transform-origin: top right; transform: rotate(-90deg) translate(-70px, -30px);height: 156.734px; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <strong>
                              {' '}
                              <span
                                style={{
                                  writingMode: 'vertical-rl',
                                  textOrientation: 'mixed',
                                  transform: 'rotate(180deg)'
                                }}
                                data-mce-style='writing-mode: vertical-rl; text-orientation: mixed; transform: rotate(180deg);'
                              >
                                Consequence
                              </span>{' '}
                            </strong>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 22.3906px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Fatality
                            </span>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            5
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            10
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            15
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            20
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            25
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 22.3906px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Major injury
                            </span>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            4
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            8
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            12
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            16
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            20
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 22.3906px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Lost time injury
                            </span>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            3
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            6
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            9
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            12
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 124, 128); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            15
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 22.3906px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Minor Injury
                            </span>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            2
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            4
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            6
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            8
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            10
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 22.3906px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Insignificant
                            </span>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            1
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            2
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            3
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(197, 224, 179); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            4
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            scope='colgroup'
                            data-mce-style='height: 22.3906px; text-align: center; vertical-align: middle; background-color: rgb(255, 229, 153); border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            5
                          </td>
                        </tr>
                        <tr
                          style={{ height: '44.7812px' }}
                          data-mce-style='height: 44.7812px;'
                        >
                          <td
                            style={{
                              height: '44.7812px',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '44.7812px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Very unlikely
                            </span>
                          </td>
                          <td
                            style={{
                              height: '44.7812px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Unlikely
                            </span>
                          </td>
                          <td
                            style={{
                              height: '44.7812px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Fairly likely
                            </span>
                          </td>
                          <td
                            style={{
                              height: '44.7812px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Likely
                            </span>
                          </td>
                          <td
                            style={{
                              height: '44.7812px',
                              textAlign: 'center',
                              borderColor: 'rgb(52, 73, 94)',
                              borderStyle: 'solid',
                              borderWidth: 1
                            }}
                            data-mce-style='height: 44.7812px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                          >
                            <span
                              style={{ fontSize: '10pt' }}
                              data-mce-style='font-size: 10pt;'
                            >
                              Very likely
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.8906px' }}
                          data-mce-style='height: 22.8906px;'
                        >
                          <td
                            style={{
                              height: '22.8906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.8906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{ height: '22.8906px', textAlign: 'right' }}
                            colSpan={6}
                            data-mce-style='height: 22.8906px; text-align: right;'
                          >
                            <strong>Likelihood</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td
                    style={{
                      verticalAlign: 'top',
                      borderStyle: 'hidden',
                      borderWidth: 0,
                      height: '274.594px'
                    }}
                    data-mce-style='vertical-align: top; border-style: hidden; border-width: 0px; height: 274.594px;'
                  >
                    <table
                      style={{
                        borderCollapse: 'collapse',
                        width: '100.162%',
                        height: '117.312px',
                        borderWidth: 1,
                        borderStyle: 'hidden'
                      }}
                      data-mce-style='border-collapse: collapse; width: 100.162%; height: 117.312px; border-width: 1px; border-style: hidden;'
                      border={1}
                    >
                      <colgroup>
                        <col
                          style={{ width: '26.6987%' }}
                          data-mce-style='width: 26.6987%;'
                        />
                        <col
                          style={{ width: '9.25757%' }}
                          data-mce-style='width: 9.25757%;'
                        />
                        <col
                          style={{ width: '2.79899%' }}
                          data-mce-style='width: 2.79899%;'
                        />
                        <col
                          style={{ width: '7.75105%' }}
                          data-mce-style='width: 7.75105%;'
                        />
                        <col
                          style={{ width: '53.6114%' }}
                          data-mce-style='width: 53.6114%;'
                        />
                      </colgroup>
                      <tbody>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            Likelihood
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='text-align: center; height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            <strong>4</strong>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              backgroundColor: 'rgb(197, 224, 179)',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; background-color: rgb(197, 224, 179); border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <span
                              style={{ fontSize: '12pt' }}
                              data-mce-style='font-size: 12pt;'
                            >
                              <strong>Low:</strong> No further action / monitor
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='text-align: center; height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            x
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              backgroundColor: 'rgb(255, 229, 153)',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; background-color: rgb(255, 229, 153); border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <span
                              style={{ fontSize: '12pt' }}
                              data-mce-style='font-size: 12pt;'
                            >
                              <strong>Medium:</strong> Monitor compliance
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            Consequence
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='text-align: center; height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            <strong>5</strong>
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; background-color: rgb(255, 124, 128); border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <span
                              style={{ fontSize: '12pt' }}
                              data-mce-style='font-size: 12pt;'
                            >
                              <strong>High:</strong> Stop &amp; review activity
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{ height: '22.3906px' }}
                          data-mce-style='height: 22.3906px;'
                        >
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='text-align: center; height: 22.3906px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            =
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '22.3906px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 22.3906px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                        </tr>
                        <tr style={{ height: '27.75px' }} data-mce-style='height: 27.75px;'>
                          <td
                            style={{
                              height: '27.75px',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='height: 27.75px; border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            Risk / Residual
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              height: '27.75px',
                              backgroundColor: 'rgb(255, 124, 128)',
                              borderStyle: 'hidden',
                              borderWidth: 0,
                              verticalAlign: 'middle'
                            }}
                            data-mce-style='text-align: center; height: 27.75px; background-color: rgb(255, 124, 128); border-style: hidden; border-width: 0px; vertical-align: middle;'
                          >
                            <strong>20</strong>
                          </td>
                          <td
                            style={{
                              height: '27.75px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 27.75px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '27.75px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 27.75px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                          <td
                            style={{
                              height: '27.75px',
                              borderStyle: 'hidden',
                              borderWidth: 0
                            }}
                            data-mce-style='height: 27.75px; border-style: hidden; border-width: 0px;'
                          >
                            <br data-mce-bogus={1} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className="page-break" />
            {
                props.data.hazards.map((item: any, index: number) => {
                  const rating = item['RR Consequence'] * item['RR Likelihood']
                  let backgroundColor = '#ff7c80'
                  let ratingText = 'High'
                  if (rating <= 4) {
                    backgroundColor = '#c5e0b3'
                    ratingText = 'Low'
                  } else if (rating > 4 && rating <= 14) {
                    backgroundColor = 'rgb(255, 229, 153)'
                    ratingText = 'Medium'
                  }
                  let controlMeasure = []
                  let peopleAtRisk = []
                  let hazards = []
                  if (typeof item.Hazard === typeof '') {
                    hazards = item.Hazard.split('\n')
                  }
                  if (typeof item['Control Measures'] === typeof '') {
                    controlMeasure = item['Control Measures'].split('\n')
                  }
                  if (typeof item['People at risk'] === typeof '') {
                    peopleAtRisk = item['People at risk'].split('\n')
                  }

                  return (
                        <>
                      <table
                        style={{
                          borderCollapse: 'collapse',
                          width: '100%',
                          height: '89.5624px',
                          border: '1px solid rgb(52, 73, 94)'
                        }}
                        data-mce-style='border-collapse: collapse; width: 100%; height: 89.5624px; border: 1px solid rgb(52, 73, 94);'
                        border={1}
                      >
                        <colgroup>
                          <col style={{ width: '17.5168%' }} data-mce-style='width: 17.5168%;' />
                          <col style={{ width: '17.5168%' }} data-mce-style='width: 17.5168%;' />
                          <col style={{ width: '5.77478%' }} data-mce-style='width: 5.77478%;' />
                          <col style={{ width: '39.846%' }} data-mce-style='width: 39.846%;' />
                          <col style={{ width: '5.67854%' }} data-mce-style='width: 5.67854%;' />
                          <col style={{ width: '13.667%' }} data-mce-style='width: 13.667%;' />
                        </colgroup>
                        <tbody>
                          <tr style={{ height: '22.3906px' }} data-mce-style='height: 22.3906px;'>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>Hazard</strong>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                textAlign: 'left',
                                padding: 8,
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; text-align: left; padding: 8px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>People at risk</strong>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                textAlign: 'center',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; text-align: center; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>Risk</strong>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>Control Measures</strong>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                textAlign: 'center',
                                padding: 8,
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; text-align: center; padding: 8px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>RR</strong>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                textAlign: 'center',
                                padding: 8,
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                verticalAlign: 'middle',
                                backgroundColor: 'rgb(236, 240, 241)'
                              }}
                              data-mce-style='height: 22.3906px; text-align: center; padding: 8px; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px; vertical-align: middle; background-color: rgb(236, 240, 241);'
                            >
                              <strong>Risk Level</strong>
                            </td>
                          </tr>
                          <tr style={{ height: '22.3906px' }} data-mce-style='height: 22.3906px;'>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                textAlign: 'left',
                                verticalAlign: 'top',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; text-align: left; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                            >
                              {hazards.map((hazard: string) => <><span key={hazard + 'hazard'}>{hazard}</span><br/></>)}
                              <br data-mce-bogus={1} />
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                textAlign: 'left',
                                verticalAlign: 'top',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; text-align: left; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                            >
                              {peopleAtRisk.map((risk: string) => <><span key={risk + 'risk'}>{risk}</span><br/></>)}
                              <br data-mce-bogus={1} />
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                textAlign: 'center',
                                verticalAlign: 'top',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1
                              }}
                              data-mce-style='height: 22.3906px; padding-top: 8px; padding-right: 8px; padding-bottom: 8px; text-align: center; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                            >
                              <p>{item.Likelihood}</p>
                              <p>x</p>
                              <p>{item.Consequence}</p>
                              <p>=</p>
                              <p>
                                <strong>{item['Risk Rating']}</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                textAlign: 'left',
                                verticalAlign: 'top',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1
                              }}
                              data-mce-style='height: 22.3906px; padding-top: 8px; padding-right: 8px; padding-bottom: 8px; text-align: left; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                            >
                              {controlMeasure.map((measure: string) => <><span key={measure} style={{ margin: '0px' }}>{measure}</span><br/></>)}
                              <br data-mce-bogus={1} />
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                textAlign: 'center',
                                verticalAlign: 'top',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1
                              }}
                              data-mce-style='height: 22.3906px; padding: 8px; text-align: center; vertical-align: top; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;'
                            >
                              <p>{item['RR Likelihood']}</p>
                              <p>x</p>
                              <p>{item['RR Consequence']}</p>
                              <p>=</p>
                              <p><strong>{item['Residual Risk']}</strong></p>
                            </td>
                            <td
                              style={{
                                height: '22.3906px',
                                padding: 8,
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                borderColor: 'rgb(52, 73, 94)',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                backgroundColor
                              }}
                              data-mce-style={() => { return 'background-color: ' + backgroundColor + ';height: 22.3906px; padding: 8px; text-align: center; vertical-align: middle; border-color: rgb(52, 73, 94); border-style: solid; border-width: 1px;' } }
                            >
                              <strong>{ratingText}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                        </>
                  )
                })
            }
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </>
  )
}

export default RiskAssessmentTemplate
