import axios from 'axios'

class APIConnection {
  userKey: string
  constructor (userKey: string) {
    this.userKey = userKey
  }

  getHeaders (): any {
    return {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.userKey}`
    }
  }

  get (): void {

  }

  async post (url: string, requestData: any): Promise<any> {
    const headers = this.getHeaders()
    return await axios.post(url, requestData, { headers })
  }
}
export default APIConnection
