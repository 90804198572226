import { GrClose } from 'react-icons/gr'
import { BiArrowBack } from 'react-icons/bi'
import React from 'react'

const BackDropdown = (props: { documentName: string }): JSX.Element => {
  const dropdownRef = React.useRef(null)
  const documentName: string = props.documentName

  function handleBackClick (): any {
    if (history.length === 1) {
      window.close()
    } else {
      history.back()
    }
  }

  return (
        <div style={{ paddingTop: '20px', position: 'sticky', top: '0', height: '30px', zIndex: '1000', backgroundColor: 'rgb(255, 255, 255)', paddingBottom: '23px', borderBottom: '1px solid #eee', paddingLeft: '10px' }}>
           <div ref={dropdownRef} style={{ display: 'flex' }}>
                { history.length === 1
                  ? <GrClose onClick={handleBackClick} className={'dropdown'} style={{ cursor: 'pointer', alignSelf: 'center' }} />
                  : <BiArrowBack onClick={handleBackClick} className={'dropdown'} style={{ cursor: 'pointer', alignSelf: 'center' }} />
                }
                <h3 style={{ margin: '5px 0px 0px 0px', alignSelf: 'center' }}>{documentName}</h3>
              </div>
         </div>
  )
}
export default BackDropdown
